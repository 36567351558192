.Page_Panel{
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 80px;
    background-color: #f2f2f2;
    position: relative;
}

.Page_Panel .inf_favorite{
    width: 410px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    position: fixed;
    right: 100px;
    bottom: 20px;
    z-index: 8000;

    align-items: center;
    border-radius: 6px;
    background-color: #f4f6f9;
    box-shadow: 0 0.3rem 1rem 0 rgba(0,0,0,.5);
}
.Page_Panel .inf_favorite .text_favorite{
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 10px;
}
.Page_Panel .inf_favorite .btn_favorite{
    background-color: #002d6a;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
    padding: 10px 0;
    text-transform: uppercase;
    width: 200px;
    text-align: center;
}

.Page_Panel .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Page_Panel .input_code{
    font-size: 14px;
    padding: 9px;
    display: block;
    width: calc(100% - 20px);
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #000000;
    font-family: 'inter';
    outline: none;
    width: 50px;
    text-align: center;
}
.Page_Panel .list_panel{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: flex-start;
    gap: 34px;
}
.Page_Panel .list_panel .show_panel{
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 15px transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: all .2s linear;
    width: 255px;
}
.Page_Panel .list_panel .show_panel:hover{
    transform: scale(0.99);
}
.Page_Panel .list_panel .show_panel .div_img{
    width: 255px;
    height: 255px;
    position: relative;
}
.Page_Panel .list_panel .show_panel .div_img .city{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #ffffff;
    text-shadow: #000 1px 1px 8px;
}
.Page_Panel .list_panel .show_panel .div_img .img_{
    width: 255px;
    height: 255px;
    position: relative;
    overflow: hidden;
}
.Page_Panel .list_panel .show_panel .div_img .img_ .img_panel{
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.Page_Panel .list_panel .show_panel .data_project{
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;    
}
.Page_Panel .list_panel .show_panel .address{
    height: 60px;
}
.Page_Panel .list_panel .show_panel .details{
    width: calc(100% - 40px);
    background-color: #002d6a;
    color: #ffffff;
    margin-top: 16px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
}

.Page_Panel .list_data_search{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border: 2px solid #c1c1c1;
    margin-top: 60px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
}
.Page_Panel .list_opt_state{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.Page_Panel .list_opt_state .show_opt_search{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.Page_Panel .list_opt_state .select_state{
    width: 236px;
}
.Page_Panel .list_opt_state .select_city{
    width: 260px;
}
.Page_Panel .list_opt_state .btn{
    background-color: #002d6a;
    color: #ffb500;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.Page_Panel .list_opt_state .btn:hover{
    background-color: #214475;
}
.Page_Panel .list_opt_state .trash{
    background-color: #ffb500;
    color: #002d6a;
}
.Page_Panel .list_opt_state .trash:hover{
    background-color: #d9a629;
}

.Page_Panel .opt_file{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    font-family: 'inter';
    gap: 0;
    position: absolute;
    right: 0;
    top: -52px;
}
.Page_Panel .opt_file .btn{
    padding: 10px;
    cursor: pointer;
    width: auto;
    text-align: center;
}
.Page_Panel .opt_file .color_1{
    width: 0px;
    height: 0px;
    border-top: 41px solid transparent;
    border-right: 20px solid #002765;
}
.Page_Panel .opt_file .btn_link{
    color: #ffffff;
    background-color: #ffb500;
}
.Page_Panel .opt_file .color_2{
    width: 0px;
    height: 0px;
    border-top: 41px solid transparent;
    border-right: 20px solid #ffb500;
    background-color: #002765;
}
.Page_Panel .opt_file .btn_file{
    color: #ffffff;
    background-color: #002765;
}
.Page_Panel .opt_file .color_3{
    width: 0px;
    height: 0px;
    border-top: 41px solid transparent;
    border-right: 20px solid #002765;
    background-color: #ffb500;
}

@media only screen and (max-width: 1200px) {
    .Page_Panel .list_opt_state{
        flex-wrap: wrap;
        gap: 16px;
    }
}
@media only screen and (max-width: 950px) {
    .Page_Panel{
        padding-top: 70px;
    }
    .Page_Panel .show_btn{
        height: calc(100% - 70px);
    }
    .Page_Panel .list_panel .show_panel{
        flex-grow: 1;
    }
    .Page_Panel .list_panel .show_panel .div_img{
        flex-grow: 1;
        width: 100%;
    }
    .Page_Panel .list_panel .show_panel .div_img .img_{
        width: 100%;
    }
}
@media only screen and (max-width: 710px) {
    .Page_Panel .opt_file{
        font-size: 12px;
    }
    .Page_Panel .opt_file .color_1, .Page_Panel .opt_file .color_2, .Page_Panel .opt_file .color_3{
        border-top: 38px solid transparent;
    }
}
@media only screen and (max-width: 670px) {
    .Page_Panel .list_opt_state{
        align-items: flex-start;
    }
}
@media only screen and (max-width: 660px) {
    .Page_Panel .list_panel .show_panel .div_img .img_ .img_panel{
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 640px) {
    .Page_Panel .list_data_search{
        margin-top: 30px;
    }
    .Page_Panel .opt_file .color_1, .Page_Panel .opt_file .color_2, .Page_Panel .opt_file .color_3{
        display: none;
    }
    .Page_Panel .opt_file .btn{
        width: calc(100% - 16px);
    }
    .Page_Panel .list_opt_state{
        order: 2;
    }
    .Page_Panel .opt_file{
        order: 1;
        flex-direction: column;
        position: relative;
        top: 0;
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 600px) {
    .Page_Panel .inf_favorite{
        width: max-content;
    }
    .Page_Panel .inf_favorite .text_favorite{
        font-size: 12px;
    }
}
@media only screen and (max-width: 580px) {
    .Page_Panel .container {
        width: calc(100% - 32px);
    }
    .Page_Panel .div_broadcast .list_data_week .opt_file .btn{
        font-size: 12px;
    }
    .Page_Panel .div_broadcast .list_data_week .opt_file .color_1, 
    .Page_Panel .div_broadcast .list_data_week .opt_file .color_2{
        border-top: 38px solid transparent;
    }
}
@media only screen and (max-width: 500px) {
    .Page_Panel .list_opt_state{
        justify-content: flex-start;
    }
    .Page_Panel .list_opt_state_btn{
        width: 100%;
    }
    .Page_Panel .list_opt_state .btn{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 460px) {
    .Page_Panel .inf_favorite{
        left: 50%;
        transform: translateX(-50%);
    }
    .Page_Panel .list_opt_state{
        font-size: 12px;
        width: 100%;
    }
    .Page_Panel .list_opt_state .btn{
        width: 100%;
        text-align: center;
    }
    .Page_Panel .list_opt_state .select_city{
        width: 250px;
    }
}