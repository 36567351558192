.Page_Product {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 60px;
}
.Page_Product .container{
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Page_Product .list_product{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 34px;
}
.Page_Product .list_product .show_product{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 360px;
    padding: 10px;
    border: 1px solid rgb(0 39 104);
    border-radius: 6px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    flex-grow: 1;
}
.Page_Product .list_product .show_product:hover{
    transform: scale(0.99);
}
.Page_Product .list_product .show_product .title{
    font-size: 32px;
    line-height: 32px;
    color: rgb(0 39 104);
    font-family: 'inter';
    font-weight: 700;
    padding: 10px 0;
}
.Page_Product .list_product .show_product .div_img{
    width: 320px;
    height: 320px;
    display: flex;
}
.Page_Product .list_product .show_product .div_img .img{
    width: 100%;
    height: 100%;
    display: flex;
}
.Page_Product .list_product .show_product .text{
    width: 320px;
    font-size: 12px;
    line-height: 14px;
    font-family: 'inter';
    padding-bottom: 20px;
}

@media only screen and (max-width: 580px) {
    .Page_Product .container {
        width: calc(100% - 32px);
    }
}