.PopUp .show_file {
    width: 900px;
    margin-bottom: 60px;
}
.PopUp .show_file .div_img{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    width: calc(100% - 8px);
    padding: 4px;
}
.PopUp .show_file .div_img .img{
    width: 100%;
    height: auto;
    box-shadow: 1px 1px 8px rgba(0,0,0,.3);
}
.PopUp .show_file .div_img .code{
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: #002d6a;
    color: #ffffff;
    padding: 10px 20px;
    border-top-left-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.PopUp .show_file .show_data_panel{
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    padding: 4px;
    gap: 20px;
}
.PopUp .show_file .show_data_panel .map{
    width: 400px;
    height: 400px;
    flex-grow: 1;
    position: relative;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
}
.PopUp .show_file .show_data_panel .map iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.PopUp .show_file .show_data_panel .description{
    background: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
    padding: 16px;
    width: 55%;
    flex-grow: 1;
}
.PopUp .show_file .show_data_panel .description .text_line{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 16px;
}
.PopUp .show_file .show_data_panel .description .text_line .div_inf_name{
    padding: 4px;
    width: calc(100% - 8px);
    color: #ffffff;
    background: #002d6a;
}
.PopUp .show_file .show_data_panel .description .text_line .show_inf_panel{
    text-align: left;
    font-size: 12px;
}


@media only screen and (max-width: 1200px) {
    .PopUp .show_file{
        width: 90%;
    }
}
@media only screen and (max-width: 800px) {
    .PopUp .show_file .div_img{
        margin-bottom: 10px;
    }
    .PopUp .show_file .show_data_panel{
        flex-direction: column;
    }
    .PopUp .show_file .show_data_panel .description{
        order: 1;
        width: calc(100% - 32px);
    }
    .PopUp .show_file .show_data_panel .map{
        order: 2;
        width: 100%;
    }
}
@media only screen and (max-width: 580px) {
    .PopUp .show_file .div_img .code {
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        gap: 0;
        background-color: rgb(0 45 106 / 40%);
    }
    .PopUp .show_file .div_img .code span{
        display: none;
    }
    .PopUp .show_file .show_data_panel .description{
        font-size: 12px;
    }
}